@import "../../colors";

$widthValue: 1170px;
$widthValue2: 767px;

.ExperienceBody {
	background-color: $mainBackgroundColor;
	padding-top: 5%;
	padding-bottom: 5%;

	h1 {
		color: $experienceTitleColor;
		padding-bottom: 5%;
	}

	.vertical-timeline {
		margin: 1em auto;
		&::before {
			background: $experienceLineColor;
			top: unset;
			height: 80%;

			@media screen and (max-width: $widthValue) {
				height: 76%;
			}
			@media screen and (max-width: $widthValue2) {
				height: 73%;
			}
		}
	}

	.vertical-timeline-element-icon {
		background: $experienceIconColor;
		box-shadow: 0 0 0 4px $experienceLineColor,
			inset 0 0px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}
	}

	.vertical-timeline-element-content {
		background: $experienceBoxColor;
		box-shadow: 0 3px 7px $experienceBoxShadowColor;

		.vertical-timeline-element-date {
			opacity: 1;
			color: $experienceDateColor;

			@media screen and (min-width: $widthValue) {
				width: 100%;
				float: left;
				text-align: left;
			}
		}

		.vertical-timeline-element-content-arrow {
			border-right: 7px solid $experienceBoxColor;
		}

		.vertical-timeline-element-title {
			color: $experienceTitleColor;
		}

		.vertical-timeline-element-subtitle {
			color: $experienceTextColor;
		}

		p {
			color: $experienceTextColor;
		}

		@media screen and (min-width: $widthValue) {
			width: 45% !important;
		}
	}
}
