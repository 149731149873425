@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import './colors';

$font-stack: 'Roboto', 'Open Sans', Helvetica, sans-serif;

.App {
	font-family: $font-stack;
	text-align: center;
	display: inline;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: $mainBackgroundColor;
}
