@import '../../colors';

$maxWidth: 767px;

.ProjectsHome {
	background-color: $mainBackgroundColor;
	padding-top: 12%;
	padding-bottom: 8%;

	h1 {
		padding-bottom: 5%;
		color: $mainTitleTextColor2;
		@media screen and (max-width: $maxWidth) {
			padding-top: 15%;
		}
	}

	.row {
		width: 100%;
		height: 100%;
		padding-left: 2%;
		padding-right: 2%;
		margin: 0;
	}

	.col-sm-4 {
		padding-top: 3%;
		margin: 0 auto;
	}
}

.ProjectCard {
	height: 100%;
	position: relative;

	.card {
		background-color: $projectCardBackground;
		border-style: none;
		box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.26),
		  0 12px 10px 0 rgba(0, 0, 0, 0.2);
		height: 100%;
		padding-bottom: 5%;
	}

	a {
		padding-bottom: 5%;
	}

	a:hover, a:visited, a:link, a:active {
		text-decoration: none;
	}

	.CardLanguages {
		width: auto;
		height: auto;
	}
}

.card-body .card-title {
	color: $projectCardTitleTextColor;
	font-size: 1.75em;
}

.card-body .card-text {
	color: $projectCardTextColor;
	font-size: 1em;
}

.devicon-github-plain {
	color: $iconColor;
	display: inline-block;
	font-size: 2em;
	text-decoration: none;
	transition: all .1s linear;
}

.devicon-github-plain:hover {
	color: $iconHoverColor;
	font-size: 2em;
	text-decoration: none;
	transform: scale(1.272);
	opacity: 1;
}