@import "../../colors";

$maxWidth: 767px;
$maxHeight: 478px;

.HomeTopBody {
	padding: 0;
	width: 100%;
	height: 100vh;
	color: $mainTitleTextColor;
	background-image: url("../../assets/images/topBg.webp");
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-color: $mainBackgroundColor;

	.me {
		position: absolute;
		margin: 0 auto;
		top: 50%;
		left: 0;
		bottom: 0;
		right: 0;
	}

	.typewriter-container {
		padding-top: 1%;
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		font-size: 3em;

		p {
			color: $mainTextColor;
			overflow: hidden;
			/* Ensures the content is not revealed until the animation */
			border-right: 0.15em solid #e3f2fd;
			/* The typwriter cursor */
			white-space: nowrap;
			/* Keeps the content on a single line */
			margin: 0 auto;
			/* Gives that scrolling effect as the typing happens */
			letter-spacing: 0.15em;
			/* Adjust as needed */
			animation: typing 3s steps(40, end), blink-caret 1s step-end infinite;
		}

		@media screen and (max-width: $maxWidth) {
			font-size: 1.5em;
			padding-top: 5%;
		}
	}
}

@keyframes typing {
	from {
		width: 0;
	}
	to {
		width: 100%;
	}
}

/* The typewriter cursor effect */
@keyframes blink-caret {
	from,
	to {
		border-color: transparent;
	}
	50% {
		border-color: #e3f2fd;
	}
}
