@import '../../colors';

.HomeBody {
	background-color: $mainBackgroundColor;
	width: 100%;

	.viewProjectsBtn {
		padding-top: 5%;
		padding-bottom: 5%;

		.btn {
			color: $mainTextColor2;
			border-color: $mainTextColor2;
			font-size: 1.5em;

			&:hover {
				color: $mainTextHoverColor;
				border-color: $mainTextHoverColor;
				background-color: $mainTextColor2;
			}
		}
	}
}