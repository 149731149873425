@import '../../colors';

.AboutMeBody {
	background-color: $mainBackgroundColor;
	padding-top: 5%;
	padding-bottom: 5%;
	color: $mainTextColor;

	h1 {
		padding-bottom: 5%;
		color: $mainTitleTextColor2;
	}

	p {
		line-height: 1.8;
		font-size: 1.2em;
		padding-bottom: 5%;
		padding-left: 10%;
		padding-right: 10%;
	}

	a {
		color: $iconColor;
		transition: all .1s linear;
		margin-right: 2%;
		margin-left: 2%;
		font-size: 1em;
		display: inline-block;
	}

	a:hover {
		transform: scale(1.272);
		color: $iconHoverColor;
		opacity: 1;
		text-decoration: none;
	}

	i {
		font-size: 2.2em;
	}
}