@import '../../colors';

.FooterBody {
	background-color: $footerBackgroundColor;
	width: 100%;
	padding-top: 1%;
	padding-bottom: 1%;

	a {
		margin-right: 10%;
		margin-left: 10%;
	}

	i {
		color: $iconColor;
		font-size: 2em;
		transition: all .1s linear;
	}

	i:hover {
		transform: scale(1.272);
		color: $iconHoverColor;
		opacity: 1;
	}
}