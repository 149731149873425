@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

.Navbar{background-color:#000;height:55px;position:fixed;top:0;width:100%;z-index:3}.Navbar a{align-items:center;color:#F3F3F3;padding-top:1%;text-align:center;justify-content:center}.Navbar a:hover{color:#F2AE0A}.Navbar a:link{text-decoration:none}.Navbar .leftSide{float:left;font-size:1.25rem;padding-left:5%}.Navbar .rightSide{align-items:center;padding-top:1%;padding-right:10%;text-align:center;float:right;font-size:1rem;justify-content:center}.Navbar .rightSideLink{padding-right:10%}.NavbarCollapse .collapseMenuItem{font-size:1rem;line-height:40px}.NavbarCollapse .hamburger-react{color:#F3F3F3;float:right;padding-right:10%}.NavbarCollapse .hamburger-react:hover{color:#F2AE0A}.NavbarCollapse .showCollapseMenuItems{padding-top:8%;padding-bottom:2%;background-color:#000}

.FooterBody{background-color:#161616;width:100%;padding-top:1%;padding-bottom:1%}.FooterBody a{margin-right:10%;margin-left:10%}.FooterBody i{color:#A3A3A3;font-size:2em;transition:all .1s linear}.FooterBody i:hover{-webkit-transform:scale(1.272);transform:scale(1.272);color:#F2AE0A;opacity:1}

.HomeBody{background-color:#000;width:100%}.HomeBody .viewProjectsBtn{padding-top:5%;padding-bottom:5%}.HomeBody .viewProjectsBtn .btn{color:#F2AE0A;border-color:#F2AE0A;font-size:1.5em}.HomeBody .viewProjectsBtn .btn:hover{color:#000;border-color:#000;background-color:#F2AE0A}

.AboutMeBody{background-color:#000;padding-top:5%;padding-bottom:5%;color:#F3F3F3}.AboutMeBody h1{padding-bottom:5%;color:#F2AE0A}.AboutMeBody p{line-height:1.8;font-size:1.2em;padding-bottom:5%;padding-left:10%;padding-right:10%}.AboutMeBody a{color:#A3A3A3;transition:all .1s linear;margin-right:2%;margin-left:2%;font-size:1em;display:inline-block}.AboutMeBody a:hover{-webkit-transform:scale(1.272);transform:scale(1.272);color:#F2AE0A;opacity:1;text-decoration:none}.AboutMeBody i{font-size:2.2em}

.ExperienceBody{background-color:#000;padding-top:5%;padding-bottom:5%}.ExperienceBody h1{color:#F2AE0A;padding-bottom:5%}.ExperienceBody .vertical-timeline{margin:1em auto}.ExperienceBody .vertical-timeline::before{background:#F2AE0A;top:unset;height:80%}@media screen and (max-width: 1170px){.ExperienceBody .vertical-timeline::before{height:76%}}@media screen and (max-width: 767px){.ExperienceBody .vertical-timeline::before{height:73%}}.ExperienceBody .vertical-timeline-element-icon{background:#fff;box-shadow:0 0 0 4px #F2AE0A,inset 0 0px 0 rgba(0,0,0,0.08),0 3px 0 4px rgba(0,0,0,0.05)}.ExperienceBody .vertical-timeline-element-icon img{width:100%;height:100%;border-radius:50%}.ExperienceBody .vertical-timeline-element-content{background:#161616;box-shadow:0 3px 7px #daa520}.ExperienceBody .vertical-timeline-element-content .vertical-timeline-element-date{opacity:1;color:#F2AE0A}@media screen and (min-width: 1170px){.ExperienceBody .vertical-timeline-element-content .vertical-timeline-element-date{width:100%;float:left;text-align:left}}.ExperienceBody .vertical-timeline-element-content .vertical-timeline-element-content-arrow{border-right:7px solid #161616}.ExperienceBody .vertical-timeline-element-content .vertical-timeline-element-title{color:#F2AE0A}.ExperienceBody .vertical-timeline-element-content .vertical-timeline-element-subtitle{color:#F3F3F3}.ExperienceBody .vertical-timeline-element-content p{color:#F3F3F3}@media screen and (min-width: 1170px){.ExperienceBody .vertical-timeline-element-content{width:45% !important}}

.HomeTopBody{padding:0;width:100%;height:100vh;color:#F3F3F3;background-image:url(/static/media/topBg.fdfa1546.webp);background-attachment:fixed;background-repeat:no-repeat;background-position:center;background-size:cover;background-color:#000}.HomeTopBody .me{position:absolute;margin:0 auto;top:50%;left:0;bottom:0;right:0}.HomeTopBody .typewriter-container{padding-top:1%;display:inline-flex;flex-direction:column;align-items:center;font-size:3em}.HomeTopBody .typewriter-container p{color:#F3F3F3;overflow:hidden;border-right:0.15em solid #e3f2fd;white-space:nowrap;margin:0 auto;letter-spacing:0.15em;-webkit-animation:typing 3s steps(40, end),blink-caret 1s step-end infinite;animation:typing 3s steps(40, end),blink-caret 1s step-end infinite}@media screen and (max-width: 767px){.HomeTopBody .typewriter-container{font-size:1.5em;padding-top:5%}}@-webkit-keyframes typing{from{width:0}to{width:100%}}@keyframes typing{from{width:0}to{width:100%}}@-webkit-keyframes blink-caret{from,to{border-color:transparent}50%{border-color:#e3f2fd}}@keyframes blink-caret{from,to{border-color:transparent}50%{border-color:#e3f2fd}}

.SkillsBody{background-image:url(/static/media/skillBg.c1edea2b.webp);background-repeat:no-repeat;background-position:center;background-size:cover;background-attachment:fixed;background-color:#000;padding-top:5%;padding-bottom:5%}.SkillsBody h1{color:#F3F3F3;padding-bottom:2%}.SkillsBody .row{width:75%;height:100%;margin:0 auto;padding-top:5%}.SkillsBody .col-sm-4{padding-top:3%;margin:0 auto}.SkillsBody i{font-size:2em;color:#F2AE0A !important}

.ProjectsHome{background-color:#000;padding-top:12%;padding-bottom:8%}.ProjectsHome h1{padding-bottom:5%;color:#F2AE0A}@media screen and (max-width: 767px){.ProjectsHome h1{padding-top:15%}}.ProjectsHome .row{width:100%;height:100%;padding-left:2%;padding-right:2%;margin:0}.ProjectsHome .col-sm-4{padding-top:3%;margin:0 auto}.ProjectCard{height:100%;position:relative}.ProjectCard .card{background-color:#161616;border-style:none;box-shadow:0 5px 5px 0 rgba(0,0,0,0.26),0 12px 10px 0 rgba(0,0,0,0.2);height:100%;padding-bottom:5%}.ProjectCard a{padding-bottom:5%}.ProjectCard a:hover,.ProjectCard a:visited,.ProjectCard a:link,.ProjectCard a:active{text-decoration:none}.ProjectCard .CardLanguages{width:auto;height:auto}.card-body .card-title{color:#F2AE0A;font-size:1.75em}.card-body .card-text{color:#F3F3F3;font-size:1em}.devicon-github-plain{color:#A3A3A3;display:inline-block;font-size:2em;text-decoration:none;transition:all .1s linear}.devicon-github-plain:hover{color:#F2AE0A;font-size:2em;text-decoration:none;-webkit-transform:scale(1.272);transform:scale(1.272);opacity:1}

.PageNotFound{background-color:#000;color:#F2AE0A;padding-top:10%;min-height:110vh;width:100%}@media screen and (max-width: 767px){.PageNotFound .container{padding-top:10%;padding-bottom:10%}}@media screen and (min-width: 767px) and (max-height: 478px){.PageNotFound .container{padding-bottom:10%}}.PageNotFound h1{font-size:7.5em;font-weight:bold}.PageNotFound h2{font-weight:bold}.PageNotFound .homeBtn{padding-top:2%}.PageNotFound .homeBtn .btn{color:#F2AE0A;border-color:#F2AE0A;font-size:1.5em}.PageNotFound .homeBtn .btn:hover{color:#000;border-color:#000;background-color:#F2AE0A}

.App{font-family:"Roboto","Open Sans",Helvetica,sans-serif;text-align:center;display:inline;flex-direction:column;align-items:center;justify-content:center;width:100%;height:100%;background-color:#000}

