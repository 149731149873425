@import "../../colors";

$maxWidth: 767px;
$maxHeight: 478px;

.PageNotFound {
	background-color: $mainBackgroundColor;
	color: $mainTitleTextColor2;
	padding-top: 10%;
	min-height: 110vh;
	width: 100%;

	.container {
		@media screen and (max-width: $maxWidth) {
			padding-top: 10%;
			padding-bottom: 10%;
		}

		@media screen and (min-width: $maxWidth) and (max-height: $maxHeight) {
			padding-bottom: 10%;
		}
	}

	h1 {
		font-size: 7.5em;
		font-weight: bold;
	}

	h2 {
		font-weight: bold;
	}

	.homeBtn {
		padding-top: 2%;
		.btn {
			color: $mainTextColor2;
			border-color: $mainTextColor2;
			font-size: 1.5em;

			&:hover {
				color: $mainTextHoverColor;
				border-color: $mainTextHoverColor;
				background-color: $mainTextColor2;
			}
		}
	}
}
