@import "../../colors";

.SkillsBody {
	background-image: url("../../assets/images/skillBg.webp");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	background-color: $mainBackgroundColor;
	padding-top: 5%;
	padding-bottom: 5%;

	h1 {
		color: $mainTitleTextColor;
		padding-bottom: 2%;
	}

	.row {
		width: 75%;
		height: 100%;
		margin: 0 auto;
		padding-top: 5%;
	}

	.col-sm-4 {
		padding-top: 3%;
		margin: 0 auto;
	}

	i {
		font-size: 2em;
		color: $mainTextColor2 !important;
	}
}