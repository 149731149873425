$experienceBoxColor: #161616;
$experienceBoxShadowColor: #DAA520;
$experienceIconColor: #FFFFFF;
$experienceLineColor: #F2AE0A;
$experienceDateColor: #F2AE0A;
$experienceTextColor: #F3F3F3;
$experienceTitleColor: #F2AE0A;

$footerBackgroundColor: #161616;

$iconColor: #A3A3A3;
$iconHoverColor: #F2AE0A;

$mainBackgroundColor: #000000;
$mainTextColor: #F3F3F3;
$mainTextColor2: #F2AE0A;
$mainTextHoverColor: #000000;
$mainTitleTextColor: #F3F3F3;
$mainTitleTextColor2: #F2AE0A;

$navBackgroundColor: #000000;
$navTextColor: #F3F3F3;
$navTextHoverColor: #F2AE0A;

$projectCardBackground: #161616;
$projectCardTextColor: #F3F3F3;
$projectCardTitleTextColor: #F2AE0A;