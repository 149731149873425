@import '../../colors';

.Navbar {
	background-color: $navBackgroundColor;
	height: 55px;
	position: fixed;
	top:0;
	width: 100%;
	z-index: 3;

	a {
		align-items: center;
		color: $navTextColor;
		padding-top: 1%;
		text-align: center;
		justify-content: center;
	}

	a:hover {
		color: $navTextHoverColor;
	}

	a:link{
		text-decoration: none;
	}

	.leftSide {
		float: left;
		font-size: 1.25rem;
		padding-left: 5%;
	}

	.rightSide {
		align-items: center;
		padding-top: 1%;
		padding-right: 10%;
		text-align: center;
		float: right;
		font-size: 1rem;
		justify-content: center;
	}

	.rightSideLink {
		padding-right: 10%;
	}
}

.NavbarCollapse {

	.collapseMenuItem {
		font-size: 1rem;
		line-height: 40px;
	}

	.hamburger-react {
		color: $navTextColor;
		float: right;
		padding-right: 10%;
	}

	.hamburger-react:hover {
		color: $navTextHoverColor;
	}

	.showCollapseMenuItems {
		padding-top: 8%;
		padding-bottom: 2%;
		background-color: $navBackgroundColor;
	}
}
